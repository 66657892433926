import Modal from "react-modal";
import ModalHeader from "../../modal-components/modal-header";
import Tag from "../../tag";
import ModalList from "../../modal-components/modal-list";
import { useEffect, useState } from "react";
import FilterModalFooter from "../../modal-components/modal-footer-filter";

const AgentOverviewFilterModal = ({isOpen, setClosed, typeOptions, regionOptions, clearFilters, agentFilterData}) => {
  const [agentTypeFilters, setAgentTypeFilters] = useState([]);
  const [regionFilters, setRegionFilters] = useState([]);

  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    setAgentTypeFilters(agentFilterData.agentTypeFilters ?? []);
    setRegionFilters(agentFilterData.regionFilters ?? []);
  }, [agentFilterData])

  const headerTags = () => (
    <>
      <Tag text="Agent Overview" type="primary" size={isMobile ? 'medium' : 'large'} />
      {!isMobile && <Tag text={`${regionFilters?.length + agentTypeFilters?.length} selected`} size={isMobile ? 'medium' : 'large'} />}
    </>
  );

  const handleAddFilter = (state, setState, filter) => {
    if (state?.includes(filter)) setState(state.filter(existingFilter => existingFilter !== filter));
    else setState([...state, filter]);
  }

  return (
    <Modal isOpen={isOpen} className='modal' overlayClassName="overlay">
      <ModalHeader title='Apply Filters' setClosed={setClosed} tags={headerTags} />

      <div className="modal__content modal-scrollbar">
        <ModalList
          title="Agent Type"
          description="Filter results by department."
          options={typeOptions}
          onClick={option => handleAddFilter(agentTypeFilters, setAgentTypeFilters, option)}
          selectedOption={agentTypeFilters}
          type="checkbox" />

        <ModalList
          title="Assigned Region"
          description="Show agents based in..."
          options={regionOptions}
          onClick={option => handleAddFilter(regionFilters, setRegionFilters, option)}
          selectedOption={regionFilters}
          type="checkbox" />

        <FilterModalFooter clearFiltersFunc={clearFilters} applyFiltersFunc={() => setClosed({agentTypeFilters, regionFilters})} />
      </div>
    </Modal>
  )
}

export default AgentOverviewFilterModal;