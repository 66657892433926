import { useEffect, useRef, useState } from 'react';
import './index.scss';
import columnData from './agentOverviewColumns';
import CaretIconWhite from '../../img/icons/caretWhite.svg';
import CaretIconDark from '../../img/icons/caretDark.svg';
import Tag from '../tag';
import {orderBy, get, filter} from "lodash";
import Counter from '../header-components/counter';
import SearchButton from '../header-components/search-button';
import SearchField from '../header-components/search-field';
import AppliedFilterTag from '../applied-filter-tag';
import { useHistory } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import EmptyTable from '../empty-table';
import Button from '../button';
import DashboardIcon from '../../img/icons/dashboard.svg'
import { Admin } from '../../fmo-api-sdk';
import moment from 'moment';
import AgentOverviewFilterModal from '../modals/agent-overview-filter';
import UserIcon from '../../img/icons/userIconNew.svg';
import MapPinFilled from '../../img/mapPinFilled.svg';
import { useSelector } from 'react-redux';
import FilterButtons from '../header-components/filter-buttons';

const AgentOverview = ({setLeftHeaderSlot, setRightHeaderSlot}) => {
  const history = useHistory();
  const staffList = useSelector((state: any) => state.staff);
  const ref = useRef<any>(null)

  const [loading, setLoading] = useState(true);
  const [visibleSections, setVisibleSections] = useState<string[]>([]);
  const [sortDir, setSortDir] = useState<string>("asc");
  const [sorter, setSorter] = useState('name');
  const [searchFilter, setSearchFilter] = useState(localStorage.getItem('agentOverviewSearch') ?? '');
  const [agentFilterData, setAgentFilterData] = useState<any>({});
  const [searchOpen, setSearchOpen] = useState(false);
  const [agentData, setAgentData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [filterOpen, setFilterOpen] = useState(false);

  const isMobile = window.innerWidth < 768;
  const singleColumns = ['active_caller_ids', 'shop_count', 'shops_cancelled', 'shops_online', 'shops_temp_offline', 'shops_to_activate', 'total_card_readers', 'total_customers', 'total_google_listings'];
  const exStaff = ['10031', '10026', '10029', '10230', '10241'];
  const regions = ['East of England', 'Midlands', 'North East', 'North West', 'Scotland', 'South West', 'Yorkshire'];
  const {agentTypeFilters, regionFilters} = agentFilterData;
  const filtersEnabled = Object.values(agentFilterData).find((agent: any) => agent?.length);
  const filtersOrSearchEnabled = searchFilter || filtersEnabled;

  useEffect(() => {
    (async () => {
      var tempAgentArray = [] as any;

      await Admin.getAgentOverviewData()
        .then(res => Object.entries(res).forEach(([key, value]) => tempAgentArray.push({'name': key, 'agentData': value})))
        .catch(error => console.log(error));

        setAgentData(tempAgentArray);

      setLoading(false)
    })()

    var savedFilters = localStorage.getItem('agentOverviewFilters');
    setAgentFilterData(savedFilters
      ? JSON.parse(savedFilters)
      : {
        agentTypeFilters: [],
        regionFilters: []
      }
    );
  }, [])

  useEffect(() => {
    if (filtersEnabled) localStorage.setItem('agentOverviewFilters', JSON.stringify(agentFilterData));
    else localStorage.setItem('agentOverviewFilters', '');
  }, [agentFilterData])

  useEffect(() => {
    setLeftHeaderSlot(<>
      <Counter counterData={(!filtersOrSearchEnabled || isMobile)
        ? filteredData.length
        : `${filteredData.length} / ${agentData.length}`} />
      </>
    );
    setRightHeaderSlot(
      <>
        <FilterButtons
          filtersEnabled={filtersOrSearchEnabled}
          openFilterModal={setFilterOpen}
          clearFilters={clearFilters}
          disabled={!agentData.length} />

        {isMobile ?
          <SearchButton onClick={() => setSearchOpen(!searchOpen)} />
          : 
          <SearchField
            onSearch={setSearchFilter}
            placeholder="Search name or region"
            disabled={!agentData.length} />}

        {/* <Button
          onClick={() => console.log('dashboard')}
          buttonText="Dashboard"
          icon={DashboardIcon}
          iconAltText='Dashboard icon'
          condensed
          disabled /> */}
      </>
    );
  }, [agentData, searchOpen, searchFilter, filteredData]);

  useEffect(() => {
    var tempFiltered = agentData;

    if (searchFilter) tempFiltered = filter(tempFiltered, agent => agent.name?.toLowerCase().includes(searchFilter?.toLowerCase()) || agent.agentData.user_region?.toLowerCase().includes(searchFilter?.toLowerCase()));
    if (agentTypeFilters?.length) {
      tempFiltered = filter(tempFiltered, agent =>
        (agentTypeFilters.includes('Field Team') && (staffList['Field Team'].find(staff => staff.value === agent.agentData.user_id) || exStaff.includes(agent.agentData.user_id))) ||
        (agentTypeFilters.includes('Office Staff') && staffList['Marketing Team'].concat(staffList['Customer Service Team']).find(staff => staff.value === agent.agentData.user_id)) ||
        (agentTypeFilters.includes('None (Internal)') && (!staffList['Field Team'].concat(staffList['Marketing Team'], staffList['Customer Service Team']).find(staff => staff.value === agent.agentData.user_id) && !exStaff.includes(agent.agentData.user_id)))
      );
    }
    if (regionFilters?.length) tempFiltered = filter(tempFiltered, agent => regionFilters.includes(agent.agentData.user_region));

    setFilteredData(tempFiltered)
  }, [agentData, searchFilter, agentFilterData]);

  useEffect(() => {
    if (searchFilter) localStorage.setItem('agentOverviewSearch', searchFilter);
    else localStorage.setItem('agentOverviewSearch', '');
  }, [searchFilter]);

  useEffect(() => {
    if (!loading) ref.current.scrollTop = parseInt(localStorage.getItem('agentOverviewScrollPos') ?? '0');
  }, [loading]);

  const sorted = orderBy(filteredData, [agent => {
    let value;
    var trimmedColName = sorter.substring(sorter.indexOf('.') + 1, sorter.length);

    if (sorter === 'user_region') value = agent.agentData.user_region;
    else if (sorter === 'name') value = agent.name;
    else if (sorter === 'orders.mom') value = agent.agentData.totals?.orders?.last_month - agent.agentData.totals?.orders?.prev_month;
    else if (sorter === 'orders.yoy') value = agent.agentData.totals?.orders?.last_month - agent.agentData.totals?.orders?.last_month_last_year;
    else if (sorter === 'total_google_listings') value = agent.agentData.totals?.total_google_listings / agent.agentData.totals?.shop_count;
    else if (singleColumns.includes(trimmedColName)) value = agent.agentData.totals?.[trimmedColName];
    else value = get(agent, `agentData.totals.${sorter}`, 0);

    if (!value) value = '-1000000000';

    // If the values are strings, make the sort case-insensitive
    if (typeof value === 'string') {
        return value.toLowerCase();
    }

    return value;
  }], [sortDir]);

  // Table sorting
  const sortTable = head => {
      let newSortDir = 'asc';
      if (sorter === head) {
          newSortDir = sortDir === 'asc' ? 'desc' : 'asc';
      }

      setSortDir(newSortDir);
      setSorter(head);
  };

  const toggleSection = (event, section) => {
    event.stopPropagation();

    if (visibleSections.includes(section)) {
      return setVisibleSections(visibleSections.filter(existingSection => existingSection !== section));
    }
    return setVisibleSections([...visibleSections, section]);
  }

  const getAgentOverviewTop = () => {
    if (isMobile) {
      if (filtersOrSearchEnabled && searchOpen) return 192;
      if (filtersOrSearchEnabled) return 144;
      if (searchOpen) return 152;
    } else if (filtersOrSearchEnabled) return 168;
    return 104;
  }

  const getColumnName = columnName => {
    if (columnName === 'This Month') return moment().format('MMM');
    if (columnName === 'Last Month') return moment().subtract(1, 'M').format('MMM');
    if (columnName === 'Prev Month') return moment().subtract(2, 'M').format('MMM');
    if (columnName === 'Prev Prev Month') return moment().subtract(3, 'M').format('MMM');
    if (columnName === 'Last Month Last Year') return moment().subtract(13, 'M').format('MMM YY');
    else return columnName
  }

  const removeFilter = (filterType, removedFilter) => {
    var tempFilterData = {...agentFilterData};
    tempFilterData[filterType] = tempFilterData[filterType].filter(filter => filter !== removedFilter);

    setAgentFilterData(tempFilterData);
  }

  const getTypeOptions = () => {
    var agentIds = agentData.map(agent => agent.agentData.user_id);
    var fieldTeamCount = agentIds.filter(id => staffList['Field Team'].find(staff => staff.value === id) || exStaff.includes(id)).length;
    var officeStaffCount = agentIds.filter(id => staffList['Marketing Team'].concat(staffList['Customer Service Team']).find(staff => staff.value === id)).length;
    var internalCount = agentIds.length - fieldTeamCount - officeStaffCount;

    return [
      {
        name: 'Field Team',
        value: 'Field Team',
        subValue: fieldTeamCount,
        disabled: !fieldTeamCount
      },
      {
        name: 'Office Staff',
        value: 'Office Staff',
        subValue: officeStaffCount,
        disabled: !officeStaffCount
      },
      {
        name: 'None (Internal)',
        value: 'None (Internal)',
        subValue: internalCount,
        disabled: !internalCount
      },
    ];
  }

  const getRegionOptions = () => {
    var regionOptions = [] as any;

    regions.forEach(region => {
      var regionCount = filter(agentData, agent => agent.agentData.user_region === region).length;

      regionOptions.push({
        name: region,
        value: region,
        subValue: regionCount,
        disabled: !regionCount,
      })
    });

    return regionOptions;
  }

  const handleFilterModalClose = filterData => {
    if (filterData) setAgentFilterData(filterData);
    setFilterOpen(false);
  }

  const clearFilters = () => {
    setAgentFilterData({
      agentTypeFilters: [],
      regionFilters: []
    });
    setSearchFilter('');
    setFilterOpen(false);
  };

  const getTrendClass = (orderData, column, cellValue) => {
    let trend;

    if (['MoM', 'YoY'].includes(column)) trend = cellValue;
    else if (column === 'Last Month') trend = orderData?.last_month - orderData?.prev_month;
    else if (column === 'Prev Month') trend = orderData?.prev_month - orderData?.prev_prev_month;
    else if (column === 'Last Month Last Year') {
      if (orderData?.last_month < orderData?.last_month_last_year * 0.75) return 'agent-overview-table__cell--warning-dark'
      if (orderData?.last_month > orderData?.last_month_last_year * 1.25) return 'agent-overview-table__cell--success-dark'
      trend = orderData?.last_month - orderData?.last_month_last_year;
    };

    if (!trend) return '';
    return trend > 0 ? 'agent-overview-table__cell--success' : 'agent-overview-table__cell--warning';
  }

  return (
    <>
      <AgentOverviewFilterModal
        isOpen={filterOpen}
        setClosed={handleFilterModalClose}
        typeOptions={getTypeOptions()}
        regionOptions={getRegionOptions()}
        clearFilters={clearFilters}
        agentFilterData={agentFilterData} />

      {searchOpen && isMobile &&
        <div className='agent-overview__search-wrapper'>
          <SearchField
            onSearch={setSearchFilter}
            placeholder="Search name or region"
            closeField={() => setSearchOpen(false)}
            disabled={!agentData.length} />
        </div>
      }

      <div className="agent-overview" ref={ref} onScroll={() => localStorage.setItem('agentOverviewScrollPos', ref.current.scrollTop)} style={{top: getAgentOverviewTop()}}>
        {filtersOrSearchEnabled &&
          <div className='agent-overview__filter-button-wrapper' style={{top: searchOpen && isMobile ? 128 : 80}}>
            {searchFilter &&
              <AppliedFilterTag
                text={searchFilter}
                removeFilter={() => setSearchFilter('')}
                size={isMobile ? 'small' : 'medium'} />}

            {agentTypeFilters?.map(type => (
              <AppliedFilterTag
                key={type}
                text={type}
                removeFilter={() => removeFilter('agentTypeFilters', type)}
                size={isMobile ? 'small' : 'medium'}
                icon={UserIcon}
                iconAlt='Agent type filter' />
            ))}

            {regionFilters?.map(region => (
              <AppliedFilterTag
                key={region}
                text={region}
                removeFilter={() => removeFilter('regionFilters', region)}
                size={isMobile ? 'small' : 'medium'}
                icon={MapPinFilled}
                iconAlt='Region filter' />
            ))}
          </div>
        }
        <h1 className='agent-overview__title'>Agent Overview</h1>

        {loading ? (
          <div className="spinner-border">
              <PuffLoader
                  size={75}
                  color={"#D82B31"}
                  loading={true}
              />
          </div>
        ) : (
          <div className="agent-overview-table__wrapper">
            {filteredData.length ? (
              <table className="agent-overview-table">
                <thead className="agent-overview-table__head">
                  <tr>
                    {columnData.map(column => {
                      if (column.ref) return (
                        column.value.map((subColumn, index) => {
                          return (visibleSections.includes(column.ref) || !index) &&
                            <th key={index}>
                              <div
                                onClick={() => sortTable(`${column?.ref}.${subColumn.value}`)}
                                className={`agent-overview-table__cell agent-overview-table__cell--header agent-overview-table__cell--${column.ref} ${!index ? 'agent-overview-table__cell--expandable' : ''}`}>

                                {(subColumn.tags?.length || !index) &&
                                  <div className={`agent-overview-table__toggle-wrapper ${!index ? 'agent-overview-table__toggle-wrapper--expandable' : ''}`}>
                                    {subColumn.tags?.length &&
                                      <div className='agent-overview-table__tag-wrapper'>
                                        {subColumn.tags.map(tag => {
                                          return <Tag key={tag.tagText} text={tag.tagText} type={tag.tagType ?? undefined} size='x-small' />
                                        })}
                                      </div>
                                    }

                                    {!index &&
                                      <img
                                        src={column.ref === 'numbers' ? CaretIconDark : CaretIconWhite}
                                        alt='Hide/show section'
                                        onClick={e => toggleSection(e, column.ref)}
                                        className={`agent-overview-table__caret-icon ${!visibleSections.includes(column.ref) ? 'agent-overview-table__caret-icon--open' : ''}`} />
                                    }
                                  </div>
                                }

                                <div>{column.ref === 'orders' ? getColumnName(subColumn.name) : subColumn.name}</div>
                              </div>
                            </th>
                        })
                      );

                      if (column.value === 'agent_name') return (
                        <th key={column.value} className="agent-overview-table__cell-wrapper--agent-name">
                          <div className={`agent-overview-table__cell agent-overview-table__cell--header agent-overview-table__cell--combined`}>
                            <div onClick={() => sortTable('name')} className='agent-overview-table__link'>{column.name}</div>
                            {!isMobile && <div onClick={() => sortTable('user_region')} className='agent-overview-table__link'>Region</div>}
                          </div>
                        </th>
                      );
                      if (column.value === 'region') return null;

                      return (
                        <th key={column.value as string} className={column.value === 'shop_count' ? 'agent-overview-table__cell-wrapper--shops' : ''}>
                          <div onClick={() => sortTable(column.value)} className={`agent-overview-table__cell agent-overview-table__cell--header agent-overview-table__cell--${column.value} ${column.value === 'shop_count' ? 'agent-overview-table__cell--shops' : ''}`}>
                            {column.tags?.length &&
                              <div className='agent-overview-table__tag-wrapper'>
                                {column.tags.map(tag => {
                                  return <Tag key={tag.tagText} text={tag.tagText} type={tag.tagType ?? undefined} size='x-small' />
                                })}
                              </div>
                            }

                            <div>{column.name}</div>
                          </div>
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  {sorted.map((agent, index) => (
                    <tr key={index} onClick={() => history.push(`/shops/region`, {id: agent.agentData.user_id, name: agent.name})} className={`agent-overview-table__row ${exStaff.includes(agent.agentData.user_id) ? 'agent-overview-table__row--ex-staff' : ''}`}>
                      {columnData.map((column: any) => {

                        if (column.value === 'agent_name') return (
                          <td key={column.value}>
                            <div className='agent-overview-table__cell agent-overview-table__cell--combined'>
                              <div className='agent-overview-table__link'>{agent.name}</div>
                              {!isMobile && <div className='agent-overview-table__region'>{agent.agentData.user_region ?? '-'}</div>}
                            </div>
                          </td>
                        );
                        if (column.value === 'region') return null;

                        if (column.value === 'total_google_listings' && agent.agentData.totals?.total_google_listings) return (
                          <td key={column.value}>
                            <div className="agent-overview-table__cell agent-overview-table__cell--g-listing">
                              <div>{agent.agentData.totals?.total_google_listings?.toLocaleString('en-GB')}</div>
                              <div>{(agent.agentData.totals?.total_google_listings / agent.agentData.totals?.shop_count).toLocaleString(undefined, {style: 'percent', maximumFractionDigits:0})}</div>
                            </div>
                          </td>
                        );

                        var cellValue = agent.agentData.totals?.[column.value]?.toLocaleString('en-GB');

                        if (column.ref) {
                          var subColumns = Object.values(column.value);

                          return (
                            subColumns.map((subColumn: any, index) => {
                              var cellValue = agent.agentData?.totals?.[column.ref]?.[subColumn.value] || agent?.agentData?.totals?.[subColumn.value];

                              if (subColumn.name === 'MoM') cellValue = (agent.agentData.totals?.orders.last_month - agent.agentData.totals?.orders.prev_month);
                              if (subColumn.name === 'YoY') cellValue = (agent.agentData.totals?.orders.last_month - agent.agentData.totals?.orders.last_month_last_year);

                              return ((visibleSections.includes(column.ref) || !index) &&
                                <td key={index}><div className={`agent-overview-table__cell ${!cellValue ? `agent-overview-table__cell--${['shops_to_activate', 'shops_temp_offline', 'shops_cancelled'].includes(subColumn.value)  ? 'success' : 'warning'}` : column.ref === 'orders' ? getTrendClass(agent.agentData?.totals?.orders, subColumn.name, cellValue) : ''}`}>
                                  {cellValue ? cellValue?.toLocaleString('en-GB') : 0}
                                </div></td>
                              )
                            })
                          )
                        }

                        return (
                          <td key={column.value}>
                            <div className={`agent-overview-table__cell ${column.value === 'shop_count' ? 'agent-overview-table__cell--shops' : ''} ${!parseInt(cellValue) ? 'agent-overview-table__cell--warning' : ''}`}>
                              {cellValue ?? 0}
                            </div>
                          </td>
                        )
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <EmptyTable type={!agentData.length ? 'none-assigned' : 'filter'} tableType='agents' />
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default AgentOverview;