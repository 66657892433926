import Modal from "react-modal";
import ModalHeader from "../../modal-components/modal-header";
import Tag from "../../tag";
import ModalList from "../../modal-components/modal-list";
import { useEffect, useState } from "react";
import FilterModalFooter from "../../modal-components/modal-footer-filter";
import UserInputHeader from "../../user-input-header";
import ModalTabs from "../../modal-components/modal-tabs";
import ModalOptions from "../../modal-components/modal-options";

const ShopListFilterModal = ({isOpen, setClosed, deviceOptions, statusOptions, clearFilters, shopFilterData, leverOptions, getMiscOptions}) => {
  const [deviceFilters, setDeviceFilters] = useState([]);
  const [statusFilters, setStatusFilters] = useState([]);
  const [leverFilters, setLeverFilters] = useState([]);
  const [miscFilters, setMiscFilters] = useState([]);
  const [miscHideFilters, setMiscHideFilters] = useState([]);
  const [activeTab, setActiveTab] = useState('Show All');
  
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    setDeviceFilters(shopFilterData.deviceFilters ?? []);
    setStatusFilters(shopFilterData.statusFilters ?? []);
    setLeverFilters(shopFilterData.leverFilters ?? []);
    setMiscFilters(shopFilterData.miscFilters ?? []);
    setMiscHideFilters(shopFilterData.miscHideFilters ?? []);
  }, [shopFilterData])

  const headerTags = () => (
    <>
      <Tag text="My Region" type="primary" size={isMobile ? 'medium' : 'large'} />
      {!isMobile && <Tag text={`${deviceFilters?.length + statusFilters?.length + leverFilters?.length + miscFilters?.length + miscHideFilters?.length} selected`} size={isMobile ? 'medium' : 'large'} />}
    </>
  )

  const handleAddFilter = (state, setState, filter) => {
    if (state?.includes(filter)) setState(state.filter(existingFilter => existingFilter !== filter));
    else setState([...state, filter]);
  }

  return (
    <Modal isOpen={isOpen} className='modal' overlayClassName="overlay">
      <ModalHeader title='Apply Filters' setClosed={setClosed} tags={headerTags} />

      <div className="modal__content modal-scrollbar">
        <ModalList
          title="Primary Shop Device"
          description="Filter results by hardware."
          options={deviceOptions}
          onClick={option => handleAddFilter(deviceFilters, setDeviceFilters, option)}
          selectedOption={deviceFilters}
          type="checkbox" />

        <ModalList
          title="Current Shop Status"
          description="Which shops would you like to see?"
          options={statusOptions}
          onClick={option => handleAddFilter(statusFilters, setStatusFilters, option)}
          selectedOption={statusFilters}
          type="checkbox" />

        <UserInputHeader title="Miscellaneous" description="Show or hide the following..." modal />
        <ModalTabs tabs={['Show All', 'Hide All']} activeTab={activeTab} setActiveTab={setActiveTab} />
        {activeTab === 'Show All' ? (
          <ModalOptions
            listOptions={getMiscOptions(miscHideFilters)}
            onClick={option => handleAddFilter(miscFilters, setMiscFilters, option)}
            selectedOption={miscFilters}
            type="checkbox" />
        ) : (
          <ModalOptions
            listOptions={getMiscOptions(miscFilters)}
            onClick={option => handleAddFilter(miscHideFilters, setMiscHideFilters, option)}
            selectedOption={miscHideFilters}
            type="checkbox" />
        )}

        <ModalList
          title="Missing Levers"
          description="Highlight shops with no..."
          options={leverOptions}
          onClick={option => handleAddFilter(leverFilters, setLeverFilters, option)}
          selectedOption={leverFilters}
          type="checkbox" />

        <FilterModalFooter clearFiltersFunc={clearFilters} applyFiltersFunc={() => setClosed({deviceFilters, statusFilters, leverFilters, miscFilters, miscHideFilters})} />
      </div>
    </Modal>
  )
}

export default ShopListFilterModal;