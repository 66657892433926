import Button from "../../button";
import ModalFooter from "../modal-footer";
import DeleteIconRed from '../../../img/icons/deleteIconRed.svg';
import DeleteIconWhite from '../../../img/icons/deleteIconWhite.svg';

const FilterModalFooter = ({sticky=false, clearFiltersFunc, applyFiltersFunc}) => (
  <ModalFooter sticky={sticky}>
    <Button
      buttonText="Clear All"
      onClick={clearFiltersFunc}
      icon={DeleteIconRed}
      iconAltText="Clear filters"
      hoverIcon={DeleteIconWhite}
      type="danger"
      grow />

    <Button
      buttonText="Reload Results"
      onClick={applyFiltersFunc}
      type="success"
      grow />
  </ModalFooter>
)

export default FilterModalFooter;