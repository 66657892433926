import { useEffect, useState } from "react";
import { useSelector} from "react-redux";
import { useParams } from "react-router-dom";
import "./index.scss";
import Overview from "./info";
import LogsHistory from "./logs";
import OrderHistory from "./orders";
import TransactionHistory from "./transactions";
import Breakdown from "./breakdown";
import he from 'he';
import {Admin} from '../../fmo-api-sdk';
// import Products from "./products";
import PuffLoader from "react-spinners/PuffLoader";
import drownDownArrow from "../../img/dropdownArrow.svg";
import useWindowSize from "../../app/helper/screen";
import { getUserId } from "../../app/auth";
import RankTag from "../rank-tag";
import Button from "../button";
import FavouriteActive from "../../img/favouriteActive.svg";
import FavouriteInactive from "../../img/favouriteInactive.svg";

const OverviewTabs = ({setHeaderTitle, setLeftHeaderSlot, setRightHeaderSlot}) => {
    // Route options
    const token = useSelector((state: any) => state.auth.token);
    const userId = getUserId(token)
    const { id }: { id: string } = useParams();
    const [activeTab, setActiveTab] = useState<number>(0);
    const [shopOverview, setShopOverview] = useState<Admin.ShopOverview>();
    const [loading, setLoading] = useState<boolean>(true);
    const [dropdownToggle, setDropdownToggle] = useState<boolean>(false);
    const [shopData, setShopData] = useState<Admin.AdminShop>();
    const [isFavourite, setIsFavourite] = useState<boolean>(false);

    const size = useWindowSize();

    //handle tabs on mobile 
    const handleTabs = function (i) {
        setActiveTab(i); 
        setDropdownToggle(false);
        window.scrollTo(0,0);
    }

    useEffect(() => {
        (async () => {
            setLoading(true);

            await Admin.getShopOverview(id)
                .then((data) => {
                    if (data) {
                        data.wholesaler_name = he.decode(data.wholesaler_name);
                        setShopOverview(data);
                    }
                })
                .catch(error => { console.log(`Error fetching overview data: ${error}`) });

            await Admin.getShopsManagedByAgent(userId)
                .then(data => {
                    var filteredShopData = data.filter(shop => shop.wholesaler_id === parseInt(id));
                    setShopData(filteredShopData[0])
                })
                .catch(error => { console.log(`Error fetching overview data: ${error}`) });

            await Admin.getFavourites(userId)
              .then(res => setIsFavourite(res.shop_favourites.includes(id) ? true :false))
              .catch(error => console.log(error));

            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        setHeaderTitle(shopOverview?.wholesaler_name);
        setLeftHeaderSlot(<div className="overview-header-id">{id}</div>);
        setRightHeaderSlot(<>
            <RankTag rank={shopOverview?.wholesaler_rank_name} pill />
            <Button
                icon={isFavourite ? FavouriteActive : FavouriteInactive}
                iconAltText={isFavourite ? "Shop favourited" : "Shop not favourited"}
                onClick={handleFavouriteclick}
                type="quaternary"
                condensed
                variant='square' />
        </>);
    }, [shopOverview, isFavourite]);

    const handleFavouriteclick = async() => {
        var favouriteToggle = isFavourite ? Admin.removeFavourite : Admin.addFavourite;

        await favouriteToggle(id)
            .then(() => {
              setIsFavourite(!isFavourite)
            })
            .catch(error => alert(error));
    };

    const tabs = [
        //@ts-ignore
        { name: "Overview", component: <Overview shop={shopOverview} shopData={shopData} /> },
        { name: "Jobs + Logs", component: <LogsHistory shopName={shopOverview?.wholesaler_name} /> },     
        /* { name: "Products", component: <Products shopId={id} /> }, */
        { name: "Order History", component: <OrderHistory shop={shopOverview} /> },     
        //@ts-ignore
        { name: "Transactions", component: <TransactionHistory shop={shopOverview} /> },
        { name: "Monthly Breakdown", component: <Breakdown shop={shopOverview} shopData={shopData} /> }
    ];

    return (
        <div id="overview-page">
            {loading ? (
                <div className="spinner-border">
                    <PuffLoader
                        size={75}
                        color={"#D82B31"}
                        loading={true}
                    />
                </div>
            ) : (
                <> {size.width !== undefined && size.width <= 768 ?
                    <div className={"tabs-dropdown-wrapper " + (dropdownToggle? "tabs-dropdown-wrapper-active" : "")}>
                        <div className="tabs-dropdown">
                            <div className={"tabs-dropdown-button " + (dropdownToggle? "tabs-dropdown-button-active" : "")}  
                            onClick={() => setDropdownToggle(!dropdownToggle)}>
                                {tabs[activeTab].name}
                                <img src={drownDownArrow} alt="expand arrow"/>
                            </div>
                            { dropdownToggle ? 
                            <div className="dropdown-tabs-list">
                                {tabs.map((tab, i) => (
                                    <div key={i} onClick={() => handleTabs(i)} 
                                    className={"overview-tab " + (activeTab === i ? "overview-tab-active" : "")}>{tab.name}</div>
                                ))}
                            </div>
                            : "" }
                        </div>
                    </div>
                    : 
                    <div className="overview-tabs">
                        {tabs.map((tab, i) => (
                            <div
                                key={i}
                                onClick={() => setActiveTab(i)}
                                className={"overview-tab " + (activeTab === i ? "overview-tab-active" : "")}>
                                {tab.name}
                            </div>
                        ))}
                    </div>}

                    <div className={`overview-content ${tabs[activeTab].name === 'Jobs + Logs' && 'overview-content--logs'}`}>
                        {tabs[activeTab].component}
                    </div>
                </>
            )}
        </div>
    )
}

export default OverviewTabs;
