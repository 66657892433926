import './index.scss';

const ModalTabs = ({tabs, activeTab, setActiveTab}) => (
  <div className="modal-tabs">
    {tabs.map(tab => (
      <div key={tab} className={`modal-tabs__tab ${tab === activeTab && 'modal-tabs__tab--active'}`} onClick={() => setActiveTab(tab)}>{tab}</div>
    ))}
  </div>
)

export default ModalTabs;