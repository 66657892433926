const columnData = [
  {name: 'Fav', value: 'favourite'},
  {name: 'ID', value: 'wholesaler_id'},
  {name: 'Rank', value: 'rank'},
  {name: 'Shop Name', value: 'wholesaler_name'},
  {name: 'Postcode', value: 'wholesaler_postcode'},
  {name: 'Device', value: 'device'},
  {name: 'Caller ID', value: 'caller_id'},
  {ref: 'orders_epos', value: [
    {name: 'This Month', value: 'instore_this_month', tags: [{tagText: 'ePOS'}, {tagText: 'IN-STORE', tagType: 'secondary'}]},
    {name: 'Last Month', value: 'instore_last_month', tags: [{tagText: 'IN-STORE', tagType: 'secondary'}]},
    {name: 'This Month', value: 'phone_this_month', tags: [{tagText: 'PHONE', tagType: 'secondary'}]},
    {name: 'Last Month', value: 'phone_last_month', tags: [{tagText: 'PHONE', tagType: 'secondary'}]},
    {name: 'This Month', value: 'card_terminal_payments_this_month', tags: [{tagText: 'CARD READER', tagType: 'secondary'}]},
    {name: 'Last Month', value: 'card_terminal_payments_last_month', tags: [{tagText: 'CARD READER', tagType: 'secondary'}]},
  ]},
  {ref: 'orders', value: [
    {name: 'Total ', value: 'total', tags: [{tagText: 'ORDERS'}]},
    {name: 'Today', value: 'today'},
    {name: 'This Week', value: 'this_week'},
    {name: 'This Month', value: 'this_month'},
    {name: 'MoM', value: 'mom'},
    {name: 'YoY', value: 'yoy'},
    {name: 'Last Month', value: 'last_month'},
    {name: 'Prev Month', value: 'prev_month'},
    {name: 'Prev Prev Month', value: 'prev_prev_month'},
    {name: 'Last Month Last Year', value: 'last_month_last_year'},
  ]},
  {ref: 'info', value: [
    {name: 'Info ', value: 'wholesaler_status', tags: [{tagText: 'STATUS'}]},
    {name: 'Signed By', value: 'signed_by'},
    {name: 'Commission', value: 'commission'},
    {name: 'Package', value: 'package_type'},
    {name: 'Sign Up Date', value: 'sign_up_date'},
    {name: 'Online Since', value: 'online_date'},
  ]},
  {ref: 'numbers', value: [
    {name: 'Last 7 Days ', value: 'last_7_days', tags: [{tagText: 'NUMBERS', tagType: 'plum'}]},
    {name: 'This Month', value: 'this_month'},
    {name: 'Last Month', value: 'last_month'},
    {name: 'Customers', value: 'customers'},
    {name: 'Caller ID', value: 'caller_id'},
    {name: 'Added Manually', value: 'added_manually'},
  ]},
  {ref: 'sms', value: [
    {name: 'Balance ', value: 'balance', tags: [{tagText: 'SMS'}]},
    {name: 'This Week', value: 'this_week'},
    {name: 'This Month', value: 'this_month'},
    {name: 'Last Month', value: 'last_month'},
  ]},
  {ref: 'marketing', value: [
    {name: 'G Listing ', value: 'google_business', tags: [{tagText: 'LEVERS'}]},
    {name: 'SMS Sent', value: 'sms'},
    {name: 'Branding', value: 'stickers'},
    {name: 'PPC', value: 'ppc'},
    {name: 'Email', value: 'email'},
    {name: 'FB Post', value: 'social'},
    {name: 'Push Notifications', value: 'push_notification'},
  ]}
];

export default columnData;